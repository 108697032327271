import React, { useContext } from "react";
import { useFormik } from "formik";
import { Button, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../authentication/AxiosController";
import { notifyError, notifySuccess } from "../toastify/toastify";
import { UserContext } from "../contexts/UserContext";

const CreatePixel = ({ showPixelModal, setShowPixelModal, fetchPixels }) => {
  const { slug, lang } = useParams();
  const { userData } = useContext(UserContext);

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      user_id: userData?.id,
      pixel_name: "",
      pixel_code: "",
      pixel_type: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      pixel_name: Yup.string().required(_("panel.pixel_name_required")),
      pixel_code: Yup.string().required(_("panel.pixel_code_required")),
      pixel_type: Yup.string().required(_("panel.pixel_type_required")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await AxiosController.post(
          `apiv2/${slug}/${lang}/builder/users-pixels`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        fetchPixels();
        notifySuccess(_("panel.successMsg"));
      } catch (error) {
        console.error("Error creating landing page:", error);
      } finally {
        setShowPixelModal(false);
        formik.resetForm();
      }
    },
  });

  return (
    <Modal show={showPixelModal} onHide={() => setShowPixelModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{_("panel.create_pixel")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dir={lang === "en" ? "ltr" : "rtl"}>
          <form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="pixel_name">
              <Form.Label>{_("panel.pixel_name")}</Form.Label>
              <Form.Control
                type="text"
                name="pixel_name"
                value={formik.values.pixel_name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.pixel_name && !!formik.errors.pixel_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.pixel_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="pixel_code">
              <Form.Label>{_("panel.pixel_code")}</Form.Label>
              <Form.Control
                type="text"
                name="pixel_code"
                value={formik.values.pixel_code}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.pixel_code && !!formik.errors.pixel_code
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.pixel_code}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="pixel_type">
              <Form.Label>{_("panel.pixel_type")}</Form.Label>
              <Form.Select
                name="pixel_type"
                value={formik.values.pixel_type}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.pixel_type && !!formik.errors.pixel_type
                }
              >
                <option value="">{_("panel.pixel_type")}</option>
                <option value="facebook">Facebook</option>
                <option value="snapchat">Snapchat</option>
                <option value="tiktok">Tiktok</option>
                <option value="google">Google</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.pixel_type}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              type="submit"
              style={{ backgroundColor: "rgba(89, 198, 201, 1)" }}
              className="btn border-0 w-100 mt-3 mb-2 py-2"
            >
              {_("panel.create")}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreatePixel;
