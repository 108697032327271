import React from "react";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../authentication/AxiosController";
import { notifyError, notifySuccess } from "../toastify/toastify";

const CreateLandingPage = ({ showModal, setShowModal, themeId }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      theme_id: themeId,
      user_custom_html: "",
      user_custom_css: "",
      store_visibility: "",
      productId: "",
      favicon: null,
      store_name: "", // Add store_name to initial values
      store_price: 0, // Add store_price to initial values
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      store_visibility: Yup.string().required("Store visibility is required"),
      productId: Yup.string().required("productId is required"),
      store_name: Yup.string().required("store_name is required"), // Validation for store_name
      store_price: Yup.number()
        .required("store_price is required")
        .min(1, "store_price must be a positive number"),
      favicon: Yup.mixed().required(_("panel.favicon") + " is required"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("theme_id", values.theme_id);
      formData.append("user_custom_html", values.user_custom_html);
      formData.append("user_custom_css", values.user_custom_css);
      formData.append("store_visibility", values.store_visibility);
      formData.append("productId", values.productId);
      formData.append("store_name", values.store_name);
      formData.append("store_price", values.store_price);
      if (values.favicon) {
        formData.append("favicon", values.favicon);
      }

      AxiosController.post(
        `apiv2/${slug}/${lang}/builder/create-landpage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          notifySuccess(_("panel.successMsg"));
          setShowModal(false);
          formik.resetForm();
        })
        .catch((error) => {
          if (error.response && typeof error.response.data === "object") {
            const errorMessages = Object.entries(error.response.data)
              .map(
                ([key, value]) =>
                  `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
              )
              .join(", ");

            notifyError(errorMessages); // Display keys and values as a string
          } else {
            notifyError(
              Array.isArray(error.response.data)
                ? error.response.data.join(", ")
                : error.response.data
            );
          }
        });
    },
  });

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{_("panel.createLanding")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          {/* Store visibility input */}
          <div className="mb-3">
            <label htmlFor="store_visibility" className="form-label">
              {_("panel.store_visibility")}{" "}
            </label>
            <select
              className={`form-control ${
                formik.touched.store_visibility &&
                formik.errors.store_visibility
                  ? "is-invalid"
                  : ""
              }`}
              id="store_visibility"
              name="store_visibility"
              value={formik.values.store_visibility}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option disabled value="">
                {_("panel.store_visibility")}
              </option>
              <option value="1">{_("panel.yes")}</option>
              <option value="0">{_("panel.no")}</option>
            </select>
            {formik.touched.store_visibility &&
              formik.errors.store_visibility && (
                <div className="invalid-feedback">
                  {formik.errors.store_visibility}
                </div>
              )}
          </div>
          {/* Favicon input */}
          <div className="mb-3">
            <label htmlFor="favicon" className="form-label">
              {_("panel.landingImage")}
            </label>
            <input
              type="file"
              className={`form-control ${
                formik.touched.favicon && formik.errors.favicon
                  ? "is-invalid"
                  : ""
              }`}
              id="favicon"
              name="favicon"
              onChange={(event) =>
                formik.setFieldValue("favicon", event.currentTarget.files[0])
              } // Handle file input change
            />
            {formik.touched.favicon && formik.errors.favicon && (
              <div className="invalid-feedback">{formik.errors.favicon}</div>
            )}
          </div>
          {/* product id  input */}
          <div className="mb-3 mt-3   d-flex align-items-center">
            <div>
              <label htmlFor="productId" className="form-label mb-0"></label>
            </div>
            <div className="flex-grow-1">
              <input
                type="text"
                className={`form-control ${
                  formik.touched.productId && formik.errors.productId
                    ? "is-invalid"
                    : ""
                }`}
                id="productId"
                placeholder={_("panel.product")}
                name="productId"
                value={formik.values.productId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.productId && formik.errors.productId && (
                <div className="invalid-feedback">
                  {formik.errors.productId}
                </div>
              )}
            </div>
            <div className="me-2">
              <Button
                type="submit"
                style={{ backgroundColor: "rgba(89, 198, 201, 1)" }}
                className="btn border-0 py-2"
              >
                {_("panel.show")}
              </Button>
            </div>
          </div>

          {/* store_name input */}
          <div className="mb-3">
            <label htmlFor="store_name" className="form-label">
              {_("panel.product_name")}
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.store_name && formik.errors.store_name
                  ? "is-invalid"
                  : ""
              }`}
              id="store_name"
              name="store_name"
              value={formik.values.store_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.store_name && formik.errors.store_name && (
              <div className="invalid-feedback">{formik.errors.store_name}</div>
            )}
          </div>

          {/* store_price input */}
          <div className="mb-3">
            <label htmlFor="store_price" className="form-label">
              {_("panel.product_price")}
            </label>
            <input
              type="number"
              className={`form-control ${
                formik.touched.store_price && formik.errors.store_price
                  ? "is-invalid"
                  : ""
              }`}
              id="store_price"
              name="store_price"
              value={formik.values.store_price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.store_price && formik.errors.store_price && (
              <div className="invalid-feedback">
                {formik.errors.store_price}
              </div>
            )}
          </div>

          {/* Submit button */}
          <Button
            type="submit"
            style={{ backgroundColor: "rgba(89, 198, 201, 1)" }}
            className="btn border-0 w-100 mt-3 mb-2 py-2"
          >
            {_("panel.create")}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateLandingPage;
