import React, { useEffect } from "react";
import { useNavigate, useParams, Route, Routes } from "react-router-dom";
import Mainbar from "./components/navbar/Mainbar";
import i18next from "i18next";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import Product from "./pages/home/Product";
import Cart from "./pages/home/Cart";
import PlaceOrder from "./pages/home/PlaceOrder";
import ProtectedRoute from "./components/authentication/ProtectedRoute";
import Panel from "./pages/panel/Panel";
import SuccessOrder from "./pages/home/SuccessOrder";
import NavigateToRoot from "./components/authentication/NavigateToRoot";
import Category from "./pages/home/Category";
import Contact from "./pages/home/Contact";
import OrderNow from "./pages/home/OrderNow"; 
import NewArrivalPage from "./pages/home/NewArrivalPage";
import MostSellingPage from "./pages/home/MostSellingPage";
import OfferItem from "./pages/home/Offer";
try {
  var MainIndex = () => {
    let { lang } = useParams();
    const isAuthenticated = localStorage.getItem("token") ? true : false;
    const role = localStorage.getItem("role")
      ? localStorage.getItem("role")
      : "";
    const Navigate = useNavigate();
    useEffect(() => {
      i18next.changeLanguage(lang);
    }, [isAuthenticated, lang, Navigate, role]);
    if (lang != "en" && lang != "ar") {
      lang = "ar";
    }
    i18next.changeLanguage(lang);

    return (
      <>
        <Mainbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/new-arrival/:id" element={<NewArrivalPage/>} />
          <Route path="/most-selling/:id" element={<MostSellingPage/>} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/panel/*" element={<Panel />} />
          
          <Route path="/cart/"
            element={
              <ProtectedRoute
                component={Cart}
                role={role}
                roles={["AFFILIATE", "ADMIN", "EMPLOYEE"]}
              />
            }
          />

          <Route path="/contact-us" element={<Contact />} />
          <Route path="/order-now/:productId/:variant" element={<OrderNow />} />
          <Route path="/offer/:id" element={<OfferItem />} />

          <Route path="/place-order/" 
          element={
              <ProtectedRoute
                component={PlaceOrder}
                role={role}
                roles={["AFFILIATE", "ADMIN", "EMPLOYEE", "FULFILLMENT"]}
              />
            }
          />

          <Route path="/success-order/"
            element={
              <ProtectedRoute
                component={SuccessOrder}
                role={role}
                roles={["AFFILIATE", "ADMIN", "EMPLOYEE", "FULFILLMENT"]}
              />
            }
          />

          <Route path="*" element={<NavigateToRoot />} />
        </Routes>
        <Footer />
      </>
    );
  };
} catch (e) {
  console.log(e);
}
export default MainIndex;
