import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import CreatePixel from '../../modals/CreatePixel';
import { notifySuccess } from '../../toastify/toastify';
import { useTranslation } from 'react-i18next';
import IconsGen from '../../icons_generator/IconsGen';
import AxiosController from '../../authentication/AxiosController';
import Loader from '../../loader/Loader';

const Pixel = () => {
  const { slug, lang } = useParams(); // Get landingId, slug, and lang from route params
  const [loading, handleLoader] = useState(false);
  const token = localStorage.getItem("token");
  const [showPixelModal, setShowPixelModal] = useState(false);
  const [pixels, setPixels] = useState([]);
  const [_] = useTranslation("global");

 

  const fetchPixels = () => {
    handleLoader(true);
    
    AxiosController.get(`apiv2/${slug}/${lang}/builder/users-pixels`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setPixels(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        console.error("Error fetching pixels:", e);
        handleLoader(false);
      });
  };

  const deletePixel = (id) => {
    handleLoader(true);
    
    AxiosController.post(`apiv2/${slug}/${lang}/builder/users-pixels/delete`, {
      pixel_id: id,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        notifySuccess(_('panel.delete_successful'));
        fetchPixels();
        handleLoader(false);
      })
      .catch((e) => {
        console.error("Error deleting pixel:", e);
        handleLoader(false);
      });
  };

  
  
  useEffect(() => { 
    fetchPixels();
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative" style={{ width: "100%" }}>
      <Helmet>
        <title>{_("panel.myLandpages")}</title>
      </Helmet>
      <CreatePixel setShowPixelModal={setShowPixelModal} showPixelModal={showPixelModal}  fetchPixels={()=>fetchPixels()} />
       
      <Loader active={loading} />

  
 

      <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.pixel")}
          </h3>
          <Button className="fs-5 px-5 w-25" style={{ backgroundColor: "rgb(89, 198, 201)", border: "none", borderRadius: "7px" }} onClick={() => setShowPixelModal(true)}>
        {_("panel.add_pixel")}
      </Button>
          
        </div>
      <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
      style={{  border:' 1px solid rgba(239, 237, 237, 1)',
        borderRadius: '40px'}}
    >
      <table className="table table-borderless">
        <thead>
          <tr>
            <th>{_("panel.time")}</th>
            <th>{_("panel.type")}</th>
            <th>{_("panel.value")}</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {pixels.length > 0 ? (
            pixels.map((pixel) => (
              <tr key={pixel?.id}>
                <td>{new Date(pixel.created_at).toLocaleString()}</td>
                <td>{pixel.type}</td>
                <td>{pixel.value}</td>
                <td onClick={() => deletePixel(pixel.id)}>
                  <IconsGen icon_name={"delete"} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">{_("panel.no_pixels")}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default Pixel;
