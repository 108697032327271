import "./style.css";
import React, { useEffect, useRef, useState } from "react";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "../../../toastify/toastify";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import IconsGen from "../../../icons_generator/IconsGen";
import * as XLSX from "xlsx";
import ConfirmModal from "../../../modals/ConfirmModal";
import CouriersCODModal from "../../../modals/CouriersCODModal";
import { Helmet } from "react-helmet";

const CouriersCOD = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [data, setData] = useState([]);
  const [success, setSuccess] = useState([]);
  const [wrong, setWrong] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const fileInputRef = useRef(null);
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  let totalPriceSysLoop = 0,
    totalPriceCourLoop = 0;
  const [totalPriceSys, setTotalPriceSys] = useState(0);
  const [totalPriceCour, setTotalPriceCour] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [formatted_data, setFormattedData] = useState();
  const direction = lang === "en" ? "rtl" : "ltr";
  const [netPrice, setNetPrice] = useState(0);
  const [file, setFile] = useState(null);

  const verifyData = (data ) => {
      
    AxiosController.post(
      `api/${slug}/${lang}/orders/verify-track-cost`,
      { tracks: data, calc_method: "COD", net_price: netPrice    },
      {
        headers: { Authorization: `Bearer ${token}`     
        
      },
      }
    )
      .then((res) => {
        setData(res.data); 
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  }; 

  const handleShowModal = () => {
    setShowConfirmModal(true);
  };

  const handleFile = (e) => {
    handleLoader(true);
    try {
      const file = e.target.files[0];  
      setFile(file)
      
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        const excelData = parsedData.map((item) => {
          return { track: item["tracks"], cost: item["COD"] };
        });
        setExcelData(excelData);
        verifyData(excelData ); 
        setFormattedData(() => {
          return excelData.reduce((acc, { track, cost }) => {
            acc[track] = cost;
            return acc;
          }, {});
        });
      };
    } catch (e) {
      handleLoader(false);
    }
  };
   const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`/${slug}/${lang}/panel/sellings/orders?${urlParams.toString()}`);
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    if (Array.isArray(data)) {
      data.forEach((order) => {
        totalPriceSysLoop += order["total_price"] || 0;

        if (formatted_data && formatted_data.hasOwnProperty(order["track"])) {
          totalPriceCourLoop += formatted_data[order["track"]] || 0;
        }
      });
    }
    setTotalPriceSys(totalPriceSysLoop);
    setTotalPriceCour(totalPriceCourLoop);
    setAdjustment();
    if (data && Array.isArray(data)) {
      setTracks(data.map((ele) => ele["track"]));
    }
  }, [data, excelData, totalPriceSysLoop, totalPriceCourLoop, adjustment]);

  const courierReport = () => {
    handleLoader(true);
 

    AxiosController.post(
      `api/${slug}/${lang}/orders/courier/report/cod`,
      {
        tracks: tracks,
        adjustment: totalPriceCour - totalPriceSys,
        account_id: 1,
        file:file
         },
      {
        headers: { Authorization: `Bearer ${token}` ,        
        "Content-Type": "multipart/form-data"
      },
      }
    )
      .then((res) => {
        setSuccess(res?.data.success);
        setWrong(res?.data.wrongTracks);
        setViewModal(true);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
        notifyError(e.response.data.error);
      });
  };
  useEffect(() => {
    handleLoader(false);
  }, [token, slug, lang, page, handleLoader]);

 
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    > <Helmet>
    <title>{_("panel.shippingSheet")}</title> 
 </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.shippingSheet")}
          </h3>
          <button
            className="btn"
            style={{ border: "none", color: "rgb(243, 168, 55)" }}
            onClick={() =>
              changeParam(
                "tracks",
                data.map((track) => track.track)
              )
            }
          >
            {_("panel.showDetailed")}
          </button>

          <div className="d-flex gap-3 ">
            <div className="d-flex flex-column ">
              <div className="form-check mb-2">
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  بعد الشحن
                </label>
                <input
                  onChange={() => setNetPrice(0)}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
              </div>
              <div className="form-check mb-2">
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  بدون شحن
                </label>
                <input
                  onChange={() => setNetPrice(1)}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
              </div>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFile}
            />
            <button
              className="btn"
              style={{ border: "1px solid #172B4D", color: "#172B4D" }}
              onClick={handleButtonClick}
            >
              <IconsGen icon_name={"excel"} /> {_("panel.uploadExcel")}
            </button>
          </div>

          <ConfirmModal
            showModal={showConfirmModal}
            setShowModal={setShowConfirmModal}
            action={courierReport}
            modalBody={` ربحية : ${(
              totalPriceCour - totalPriceSys
            ).toLocaleString()}`}
            modalHeader="shippingDifferent"
            confirmButton="confirm"
          />
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {data.length && (
            <PaymentRequestsTableRow
              payments_list={data}
              handleLoader={handleLoader}
              excelData={excelData}
            />
          )}
          <CouriersCODModal
            viewModal={viewModal}
            setViewModal={setViewModal}
            wrong={wrong}
            data={data}
            success={success}
          />
        </div>
        {data.length > 0 && (
          <button
            className="btn p-2 mt-4"
            style={{ background: "#59C6C9", color: "white", border: "none" }}
            onClick={() => handleShowModal()}
          >
مراجعة تقفيلات          </button>
        )}
      </div>
    </div>
  );
};

export default CouriersCOD;
