import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom"; 
import DateRangePickerComponent from "../../../../date/DateRangePickerComponent";
import AxiosController from "../../../../authentication/AxiosController";
import { MultiSelect } from "primereact/multiselect";
import { format } from "date-fns";

const StatisticsSearchComponent = ({ type }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token"); 
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [marketer_id, handleMarketer_id] = useState("");
  const [courier_id, handleCourier_id] = useState("");
  const [product_id, handleProduct_id] = useState("");
  const [days, handleDays] = useState("");
  const [userId, handleUserId] = useState("");
  const [seller_id, handleSeller_id] = useState("");
  const [sku, handleSku] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const cours = [];
  const [couriers, setCouriers] = useState([]);
  const [selectedCouriers, setSelectedCouriers] = useState([]);
 
  couriers.forEach((gov) => {
    cours.push({ name: gov.name, Code: gov.id });
  });
  const fetchCouriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => { 
        
        setCouriers(res.data);
      })
      .catch((e) => {});
  };
 
 
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;  
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));
  
    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, 'yyyy-MM-dd');
    const apiEndDate = format(formattedEndDate, 'yyyy-MM-dd'); 
  };
  const changeParam = () => {
    const courierIds = selectedCouriers.map(courier => courier.Code).join(",");
    urlParams.set("courier_id",courierIds); 
    urlParams.set("marketer_id", marketer_id); 
    urlParams.set("product_id", product_id);
    urlParams.set("days", days);
    urlParams.set("user_id", userId);
    urlParams.set("seller_id", seller_id);
    urlParams.set("sku", sku);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    handleMarketer_id("");
    handleCourier_id("");
    setSelectedCouriers([]);
    handleProduct_id("");
    handleDays("");
    handleUserId("");
    handleSku("");
    handleEndDate("");
    Navigate("");
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };
  
  useEffect(() => {
    fetchCouriers(); 
  }, []); 

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeParam();
      }}
    >
      <div className="w-100 d-flex flex-column gap-1">
        <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
        {type == "all_orders" &&
            <>
              
              <Col>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.themarketer")}
                    onChange={(e) => {
                      handleMarketer_id(e.target.value);
                    }}
                    value={marketer_id}
                  />
                </Form.Group>
              </Col> 
              <Col>
          <Form.Group className=" flex-grow-1 h-100">
            <div className="d-flex justify-content-center h-75">
              <MultiSelect
                value={selectedCouriers}
                onChange={(e) => {
                  setSelectedCouriers(e.target.value);
                }}
                options={cours}
                optionLabel="name"
                placeholder={_("panel.Courier")}
                maxSelectedLabels={3}
                className="w-100 align-items-center px-3 py-1"
              />
            </div>
          </Form.Group>
        </Col>
              <Col>
                <Form.Group    
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder="Sku"
                    onChange={(e) => {
                      handleSku(e.target.value);
                    }}
                    value={sku}
                  />
                </Form.Group>
              </Col> 
              <Col>
              <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <DateRangePicker onApply={handleApply}>
                    <input type="text" className="form-control" />
                  </DateRangePicker>
      
                     </Form.Group>

              </Col>
            </> 
          }
        {type == "payment" &&
            <>
              <Col>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.code")}
                    onChange={(e) => {
                      handleUserId(e.target.value);
                    }}
                    value={userId}
                  />
                </Form.Group>
              </Col> 
              <Col>
              <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <DateRangePicker onApply={handleApply}>
                    <input type="text" className="form-control" />
                  </DateRangePicker>
              {/* <DateRangePickerComponent 
                state={state} 
                setState={setState}  
                applyDateRange={()=>applyDateRange()}
              />          */}
                     </Form.Group>

              </Col>
            </> 
          }
                   {type == "stagnantProducts" &&
            <>
              <Col>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.productId")}
                    onChange={(e) => {
                      handleProduct_id(e.target.value);
                    }}
                    value={product_id}
                  />
                </Form.Group>
              </Col>     <Col>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.days")}
                    onChange={(e) => {
                      handleDays(e.target.value);
                    }}
                    value={days}
                  />
                </Form.Group>
              </Col>  
            </> 
          }
          {type == "product" &&
            <>
              <Col>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.productId")}
                    onChange={(e) => {
                      handleProduct_id(e.target.value);
                    }}
                    value={product_id}
                  />
                </Form.Group>
              </Col> 
              <Col>
                <Form.Group className="" controlId="exampleForm.ControlInput1">
                  <DateRangePicker onApply={handleApply}>
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                </Form.Group>
              </Col>
            </> 
          }
                {type == "sales" &&
            <>
                <Col>
              <Form.Group
                className="mb-3 flex-grow-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder={_("panel.sellerId")}
                  onChange={(e) => {
                    handleSeller_id(e.target.value);
                  }}
                  value={seller_id}
                />
              </Form.Group>
            </Col>
               <Col>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.themarketer")}
                    onChange={(e) => {
                      handleMarketer_id(e.target.value);
                    }}
                    value={marketer_id}
                  />
                </Form.Group>
              </Col> 
              <Col>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.productId")}
                    onChange={(e) => {
                      handleProduct_id(e.target.value);
                    }}
                    value={product_id}
                  />
                </Form.Group>
              </Col> 
              <Col>
                <Form.Group className="" controlId="exampleForm.ControlInput1">
                  <DateRangePicker onApply={handleApply}>
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                </Form.Group>
              </Col>
              
            </> 
          }
          {type === "seller" && (
            <Col>
              <Form.Group
                className="mb-3 flex-grow-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder={_("panel.sellerId")}
                  onChange={(e) => {
                    handleSeller_id(e.target.value);
                  }}
                  value={seller_id}
                />
              </Form.Group>
            </Col>
          )}

{type == "buyer" &&(
            <>
 
              <Col>
                <Form.Group className="" controlId="exampleForm.ControlInput1">
                  <DateRangePicker onApply={handleApply}>
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                </Form.Group>
              </Col>
            </>
          )}
        </div>
        <div className="action-inputs mt-3">
          <input
            className=" px-5 align-self-end py-2"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
              color: "white",
              textAlign: "center",
            }}
            onClick={() => {
              changeParam();
            }}
            type="submit"
            value={_("panel.search")}
          />
          <Button
            style={{ backgroundColor: "rgb(23, 43, 77)" }}
            variant="dark"
            className="d-inline"
            onClick={resetHandler}
          >
            {_("panel.reset")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default StatisticsSearchComponent;
