import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AxiosController from "../../../components/authentication/AxiosController";
import { useTranslation } from "react-i18next";
import PlaceCartItem from "../../../components/cart/PlaceCartItem";
import Loader from "../../../components/loader/Loader";
import { Form } from "react-bootstrap";

import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import BaseServerUrl from "../../authentication/BaseServerUrl";
import IconsGen from "../../icons_generator/IconsGen";
import { Helmet } from "react-helmet";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function isValidSubdomain(text) {
  const regex = /^[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?$/;
  return regex.test(text);
}

const Store = () => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");

  // const [governmentAreas, handleGovAreas] = useState([]);
  const [loading, handleLoader] = useState(false);

  const [storeName, handleStoreName] = useState("");
  const [storeDomain, handleStoreDomain] = useState("yourdomain");

  const [storeNameErr, handleStoreNameErr] = useState("");
  const [storeDomainErr, handleStoreDomainErr] = useState("");
  const [storeErr, handleStoreErr] = useState("");

  const [success, handleSuccess] = useState("");
  const [files2, setFiles2] = useState([]);
  const acceptedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpg",
  ];
  const pond2 = useRef(null);

  const instanceObject = {
    pixel_code: "",
    pixel_code_type: "",
    pixelCodeErr: "",
    typeErr: "",
  };
  const [landpageOffers, handleLandpageOffers] = useState([instanceObject]);
  const [landpageOffersErr, handleLandpageOffersErr] = useState("");

  const [uploadedLandpage, setUploadedLandpage] = useState("");
  const create_shop = () => {
    let valid = true;

    let landpageNewOffers = landpageOffers.map((offer, index) => {
      let offerInstance = offer;
      offerInstance.pixelCodeErr = "";
      offerInstance.typeErr = "";

      if (offerInstance.pixel_code == "") {
        offerInstance.pixelCodeErr = _("panel.required");
        valid = false;
      }
      if (offerInstance.pixel_code_type == "") {
        offerInstance.typeErr = _("panel.required");
        valid = false;
      }
      return offerInstance;
    });

    handleLandpageOffers(landpageNewOffers);

    if (valid) {
      handleLoader(true);
      AxiosController.post(
        `api/${slug}/${lang}/store/create`,
        {
          name: storeName,
          subdomain: storeDomain,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          handleStoreName("");
          handleStoreDomain("");
          handleSuccess(_("panel.shopCreated"));
          setUploadedLandpage(res.data.store);
          if (landpageOffers.length > 0) {
            AxiosController.post(
              `api/${slug}/${lang}/store/${res.data.store}/pixel-code/add`,
              {
                pixels_code: landpageOffers,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
              .then((res) => {
                handleLandpageOffers(() => {
                  pond2.current.processFiles().then(() => {
                    handleLoader(false);
                  });
                  return landpageOffers.map((offer, index) => {
                    let offerInstance = offer;
                    offerInstance.typeErr = "";
                    offerInstance.pixelCodeErr = "";
                    offerInstance.pixel_code = "";
                    offerInstance.pixel_code_type = "";
                    return offerInstance;
                  });
                });
              })
              .catch((e) => {});
          } else {
            handleLandpageOffers(() => {
              pond2.current.processFiles().then(() => {
                handleLoader(false);
              });
              return landpageOffers.map((offer, index) => {
                let offerInstance = offer;
                offerInstance.typeErr = "";
                offerInstance.pixelCodeErr = "";
                offerInstance.pixel_code = "";
                offerInstance.pixel_code_type = "";
                return offerInstance;
              });
            });
          }
        })
        .catch((e) => {
          handleLoader(false);
          handleStoreNameErr(
            e.response.data.error.name ? e.response.data.error.name[0] : ""
          );
          handleStoreErr(
            e.response.data.error.store ? e.response.data.error.store[0] : ""
          );
          handleStoreDomainErr(
            e.response.data.error.subdomain
              ? e.response.data.error.subdomain[0]
              : ""
          );
        });
    }
  };

  return (
    <Container style={{ position: "relative" }}>
      <Loader active={loading} />
      <Helmet>
        <title>{_("panel.createShop")}</title>
      </Helmet>
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.createShop")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <div
            className="order-right-container d-flex flex-column"
            style={{ flex: "7" }}
          >
            <div className="order-right-entry">
              <div className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4">
                <div
                  className="right-side d-flex flex-column"
                  style={{ flex: "3" }}
                >
                  <div className="right-head">
                    <h3>{_("panel.shopDetails")}</h3>
                    <p>{_("panel.verifystore")}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.storeDomain")}
                    </label>
                    {storeErr ? (
                      <Alert variant={"danger"}>{storeErr}</Alert>
                    ) : (
                      ""
                    )}
                    <div className="right-head">
                      <p>{storeDomain + ".tejarty.store"}</p>
                    </div>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={storeDomain}
                      onInput={(e) => {
                        handleStoreDomain(
                          isValidSubdomain(e.target.value) |
                            (e.target.value == "")
                            ? e.target.value
                            : "yourdomain"
                        );
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.storeDomain")}
                    </label>
                    {storeErr ? (
                      <Alert variant={"danger"}>{storeErr}</Alert>
                    ) : (
                      ""
                    )}
                    <div className="right-head">
                      <p>{storeDomain + ".tejarty.store"}</p>
                    </div>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={storeDomain}
                      onInput={(e) => {
                        handleStoreDomain(
                          isValidSubdomain(e.target.value) |
                            (e.target.value == "")
                            ? e.target.value
                            : "yourdomain"
                        );
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.storeDomain")}
                    </label>
                    {storeErr ? (
                      <Alert variant={"danger"}>{storeErr}</Alert>
                    ) : (
                      ""
                    )}
                    <div className="right-head">
                      <p>{storeDomain + ".tejarty.store"}</p>
                    </div>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={storeDomain}
                      onInput={(e) => {
                        handleStoreDomain(
                          isValidSubdomain(e.target.value) |
                            (e.target.value == "")
                            ? e.target.value
                            : "yourdomain"
                        );
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    {success ? (
                      <Alert variant={"success"}>{success}</Alert>
                    ) : (
                      ""
                    )}
                    {storeDomainErr ? (
                      <Alert variant={"danger"}>{storeDomainErr}</Alert>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3">
                    <Button
                      style={{
                        backgroundColor: "rgba(89, 198, 201, 1)",
                        color: "white",
                        border: "none",
                        width: "100%",
                      }}
                      className="fs-5"
                      onClick={() => {
                        if (
                          storeDomain != "yourdomain" &&
                          storeDomain != "test"
                        ) {
                          create_shop();
                        }
                      }}
                    >
                      {_("panel.createShop")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Store;
