import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom"; 
import { FaMoneyBillTrendUp } from "react-icons/fa6";

const Sales = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  return (
    <div className="links-container d-flex flex-column">

      <NavLink
        className={`feature_link ${
          lang === "en" ? "feature_link_en" : ""
        } d-flex flex-row-reverse align-items-center gap-2 py-3`}
        dir={direction}
        to={`/${slug}/${lang}/panel/sales`}
      >
   <FaMoneyBillTrendUp     className='icon'  />

        <p>{_("panel.sales")}</p>
      </NavLink>
     
    </div>
  );
};

export default Sales;
