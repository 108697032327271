import React, { useEffect, useRef, useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { currancyConvertor } from "../../../../unit/currancyConvertor";
import { Form, Table } from "react-bootstrap";
import PieChartTemplate from "../../../charts/ChartTemplate";
import { Pie } from "react-chartjs-2";
import { MultiSelect } from "primereact/multiselect";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format } from "date-fns";

const Couriers = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [statsArray, SetstatsArray] = useState([0, 0, 0, 0]);
  const [coureris, setCouriers] = useState([]);
  const [grouped_stats_status, setgrouped_stats_status] = useState([]);
  const [grouped_stats_areas, setgrouped_stats_areas] = useState([]);
  const [grouped_stats_products, setgrouped_stats_products] = useState([]);
  const [productsTotalOrders, setProductsTotalOrders] = useState(1);
  const [productsTotalOrders2, setProductsTotalOrders2] = useState(0);
  const [productsTotalOrders3, setProductsTotalOrders3] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentCourier, setCurrentCourier] = useState();
  const [selectedGovs, setSelectedGovs] = useState([]);
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),                
      key: 'selection'
    }
  });

  const govs = [];

  coureris.forEach((gov) => {
    govs.push({ name: gov.name, Code: gov.id });
  });
  const status_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-status-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let total = res.data.reduce((count, i) => count + i["orders_count"], 0);
        setTotalOrders(total || 1);
        setgrouped_stats_status(res.data);
        res.data.map((status_stat) => {
          if (
            status_stat["status"] == "Cash Delivery" ||
            status_stat["status"] == "Delivered"
          )
            SetstatsArray((arr) => {
              let tempArr = arr;
              tempArr[0] += (status_stat["orders_count"] / total) * 100;
              return arr;
            });

          if (
            status_stat["status"] == "Canceled" ||
            status_stat["status"] == "Pending Cancel"
          )
            SetstatsArray((arr) => {
              let tempArr = arr;
              tempArr[1] += (status_stat["orders_count"] / total) * 100;
              return arr;
            });

          if (status_stat["status"] == "Pending Return")
            SetstatsArray((arr) => {
              let tempArr = arr;
              tempArr[2] += (status_stat["orders_count"] / total) * 100;
              return arr;
            });

          if (status_stat["status"] == "Shipped")
            SetstatsArray((arr) => {
              let tempArr = arr;
              tempArr[3] += (status_stat["orders_count"] / total) * 100;
              return arr;
            });
        });
      })
      .catch((e) => {});
  };
  const area_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-area-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let totalAreaOrders = res.data.reduce((prv, prd_order) => {
          return prd_order["stats"] + prv;
        }, 0);
        setProductsTotalOrders3(totalAreaOrders);

        setgrouped_stats_areas(res.data);
      })
      .catch((e) => {});
  };
  const products_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-product-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let totalProductsOrders = res.data.reduce((prv, prd_order) => {
          return Number(prd_order["totalQ"]) + prv;
        }, 0);
        let totalProductsOrders2 = res.data.reduce((prv, prd_order) => {
          return Number(prd_order["deliveredQ"]) + prv;
        }, 0);
        setProductsTotalOrders(totalProductsOrders);
        setProductsTotalOrders2(totalProductsOrders2);
        setgrouped_stats_products(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const get_couriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCouriers(res.data);
      })
      .catch((e) => {});
  };
  

  useEffect(() => {
    handleLoader(true);
    get_couriers();
    handleLoader(false);
  }, [slug, lang, token, handleLoader]);
  const totalShippedCount = grouped_stats_products.reduce(
    (acc, item) => acc + item.shipped_count,
    0
  );
  const totalOrdersNum = grouped_stats_products.reduce(
    (acc, item) => acc + item.total_product_orders,
    0
  );
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection; 
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));
  
    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, 'yyyy-MM-dd');
    const apiEndDate = format(formattedEndDate, 'yyyy-MM-dd');
    handleLoader(true);
    status_grouped_stats(selectedGovs, apiStartDate, apiEndDate);
    area_grouped_stats(selectedGovs, apiStartDate, apiEndDate);
    products_grouped_stats(selectedGovs, apiStartDate, apiEndDate);
    SetstatsArray([0, 0, 0, 0]);
    handleLoader(false);
  };           
  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.couriers")}</title>
      </Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.couriers")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
          <DateRangePickerComponent 
                state={state} 
                setState={setState}    
                applyDateRange={()=>applyDateRange()}
              />
           
          </div>
        </div>
        <div
          className="boxes-container d-flex flex-wrap gap-2"
          // style={{ direction: "rtl" }}
          dir={box_head_dir}
        >
          <div className="d-flex justify-content-center w-100 gap-2 h-100">
            <MultiSelect
              value={selectedGovs}
              onChange={(e) => {
                setSelectedGovs(e.value);
              }}
              options={govs}
              optionLabel="name"
              placeholder={_("panel.couriers")}
              maxSelectedLabels={3}
              className="w-100 align-items-center px-3 py-1"
            />
            <button
              className="btn px-3"
              style={{
                background: "rgba(89, 198, 201, 1)",
                color: "white",
              }}
              onClick={(e) => {
                handleLoader(true);
                setProductsTotalOrders(1);
                setProductsTotalOrders2(1);
                setProductsTotalOrders3(1);
                status_grouped_stats(selectedGovs, startDate, endDate);
                area_grouped_stats(selectedGovs, startDate, endDate);
                products_grouped_stats(selectedGovs, startDate, endDate);
                setCurrentCourier(selectedGovs);
                SetstatsArray([0, 0, 0, 0]);

                // handleLoader(false);
              }}
            >
              {_("panel.show")}
            </button>
          </div>
          <div className="report-box col-12 col-lg-2 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"dollarSign"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.نسبة التسليمات")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsArray[0].toLocaleString()}
                {"%"}
              </p>
            </div>
          </div>
          <div className="report-box col-12 col-lg-2 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.نسبة الإلغاءات")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsArray[1].toLocaleString()}
                {"%"}
              </p>
            </div>
          </div>
          <div className="report-box col-12 col-lg-2 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"dollarSign"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.نسبة المرتجعات")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsArray[2].toLocaleString()}

                {"%"}
              </p>
            </div>
          </div>

          <div className="report-box col-12 col-lg-2 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.في الشحن")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsArray[3].toLocaleString()}

                {"%"}
              </p>
            </div>
          </div>
        </div>

        <div className="chart-container row">
          <div className="col-2 col-5 m-auto">
            <Pie
              data={dataConstructor(
                grouped_stats_areas,
                "arabic_name",
                productsTotalOrders3
              )}
              className="text-center"
            />
          </div>
        </div>
      </div>
      <Table
        responsive
        dir={box_head_dir}
        className="reports-table text-nowrap text-center"
      >
        <thead>
          <tr>
            <th>{_("panel.status")}</th>
            <th>
              {_("panel.totalorders2")} ({totalOrders})
            </th>
            <th>{_("panel.value")}</th>
            <th>{_("panel.shippingCostsValue")}</th>
          </tr>
        </thead>
        <tbody>
          {grouped_stats_status.map((stat) => {
            return (
              <tr>
                <td>{_(`panel.${stat["status"]}`)}</td>
                <td>
                  {stat["orders_count"]} (
                  {(
                    (stat["orders_count"] / totalOrders) *
                    100
                  ).toLocaleString()}
                  %)
                </td>
                <td>{stat["value"].toLocaleString()}</td>
                <td>{stat["shipping_cost"].toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Table
        responsive
        dir={box_head_dir}
        className="reports-table text-nowrap text-center mt-5"
      >
        <thead>
          <tr>
            <th>{_("panel.title")}</th>
            <th>
               
                  {_("panel.totalorders2")} ({totalOrders})
            </th>
            <th>
              <span className="text-secondary"></span> 
                 {_("panel.totalQ")} ({productsTotalOrders}) 
            </th>
            <th>
              <span className="text-secondary">
               
              </span> 
              {_("panel.deliveredOrders")} ({Math.round((statsArray[0] / 100) * totalOrders)})    (
                {Number((Math.round((statsArray[0] / 100) * totalOrders) /totalOrders) *100).toLocaleString()} %)
                  
            </th>
            <th>
               
                
              {_("panel.totalDeliveredQ")} ({productsTotalOrders2})
            </th>
            <th>
              {_("panel.shippedOrders")}
            </th>
          </tr>
        </thead>
        <tbody>
          {grouped_stats_products.map((stat) => {
            return (
              <tr>
                <td>
             <span className="fw-bold">  #{stat["product"]["id"]}</span>    <br/>  {stat["product"]["title"]}
                </td>
                <td>{stat["totalOrders"]}</td>
                <td>{stat["totalQ"]}</td>
                <td>
                  {stat["deliveredOrders"]} (
                  {Number(
                    (stat["deliveredOrders"] / stat["totalOrders"]) * 100
                  ).toLocaleString()}
                  %)
                </td>
                <td>{stat["deliveredQ"]} </td>
                <td>{stat["totalShippedOrders"]} </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
const dataConstructor = (
  dataArray,
  labelName,
  totalAreaOrders,
  valueName = "stats"
) => {
  return {
    labels: dataArray.map((data) => data[labelName]),
    datasets: [
      {
        data: dataArray.map((data) => data["stats"].toLocaleString()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
      },
    ],
  };
};
export default Couriers;
